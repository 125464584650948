import React, { useState, useMemo } from "react";
import { HelpCircle, Calendar, TrendingUp, AlertTriangle } from "lucide-react";
import { useSessionStore } from "@/providers/store";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { cn } from "@/lib/utils";
import { Pane } from "../Pane";
const ForecastUsage: React.FC = () => {
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const { locationSpec } = useSessionStore();
  const onEditUsage = () => {
    console.log("Edit Usage");
  };

  const currentUsage = 1000;

  // Base factors for each month relative to average (1.0)
  const monthlyFactors = {
    Nov: 0.75,
    Dec: 0.85,
    Jan: 0.75,
    Feb: 0.7,
    Mar: 0.7,
    Apr: 0.7,
    May: 1.0,
    Jun: 1.4,
    Jul: 1.6,
    Aug: 1.8,
    Sep: 1.4,
    Oct: 0.85,
  };

  // Calculate actual usage data based on current average usage
  const data = useMemo(() => {
    return Object.entries(monthlyFactors).map(([month, factor]) => ({
      month: `${month} ${month === "Oct" ? "31" : "30"}`,
      usage: Math.round(currentUsage * factor),
      label: month,
      factor,
    }));
  }, [currentUsage]);

  const annualUsage = useMemo(() => {
    return data.reduce((sum, month) => sum + month.usage, 0);
  }, [data]);

  const peakMonth = useMemo(() => {
    return data.reduce(
      (max, item) => (item.usage > max.usage ? item : max),
      data[0]
    );
  }, [data]);

  const peakIncrease = useMemo(() => {
    return (((peakMonth.usage - currentUsage) / currentUsage) * 100).toFixed(0);
  }, [peakMonth, currentUsage]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-100">
          <p className="font-medium text-gray-900 mb-1">{data.label}</p>
          <div className="flex items-baseline gap-1">
            <span className="text-lg font-semibold text-blue-600">
              {payload[0].value.toLocaleString()}
            </span>
            <span className="text-sm text-gray-500">kWh</span>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Pane className="w-full border-2 border-gray-200 rounded-lg bg-white">
      <div className="space-y-4">
        {/* Header */}
        <div className="flex items-start justify-between">
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <div className="p-1.5 bg-blue-50 rounded-lg">
                <TrendingUp className="w-4 h-4 text-blue-600" />
              </div>
              <h2 className="text-base font-bold text-gray-900">
                Your Forecasted Usage
              </h2>
            </div>
            <div className="flex items-center gap-1.5">
              <Calendar className="w-3.5 h-3.5 text-gray-400" />
              <p className="text-xs text-gray-500">
                November 2024 - October 2025
              </p>
            </div>
          </div>
          <div className="relative">
            <button
              className="bg-gray-50 rounded-full p-1.5 hover:bg-gray-100 transition-colors group focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-label="Learn more about forecasted usage"
              onMouseEnter={() => setShowHelp(true)}
              onMouseLeave={() => setShowHelp(false)}
            >
              <HelpCircle className="w-3.5 h-3.5 text-gray-400 group-hover:text-gray-500" />
            </button>
            {showHelp && (
              <div className="absolute right-0 top-8 w-48 p-2 bg-white rounded-lg shadow-lg border border-gray-100 text-xs text-gray-600 z-10 animate-fade-in">
                Your estimated electricity usage based on home size and seasonal
                patterns
              </div>
            )}
          </div>
        </div>

        {/* Chart */}
        <div className="h-[85px] w-full -ml-2">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data}
              margin={{ top: 5, right: 5, left: -20, bottom: 0 }}
              onMouseEnter={() => setIsTooltipActive(true)}
              onMouseLeave={() => setIsTooltipActive(false)}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={true}
                vertical={false}
                stroke="#E5E7EB"
              />
              <XAxis
                dataKey="month"
                tick={{ fontSize: 7, fill: "#6B7280" }}
                tickLine={false}
                axisLine={false}
                interval={3}
                tickMargin={2}
              />
              <YAxis
                tick={{ fontSize: 7, fill: "#6B7280" }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) =>
                  value === 0 ? "0" : `${value / 1000}k`
                }
                domain={[0, Math.max(2000, peakMonth.usage * 1.1)]}
                ticks={[0, currentUsage, Math.max(2000, peakMonth.usage)]}
              />
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ fill: "#F3F4F6" }}
              />
              <ReferenceLine
                y={currentUsage}
                stroke="#9CA3AF"
                strokeDasharray="3 3"
                isFront={false}
              />
              <Bar
                dataKey="usage"
                fill="#4F46E5"
                radius={[1, 1, 0, 0]}
                maxBarSize={8}
                className={cn(
                  "transition-opacity duration-200",
                  isTooltipActive ? "opacity-70" : "opacity-100"
                )}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Usage Stats */}
        <div className="grid grid-cols-2 gap-3">
          <div className="space-y-0.5">
            <p className="text-[10px] font-medium text-gray-500 uppercase tracking-wide">
              Average Usage
            </p>
            <p className="text-sm font-semibold text-gray-900">
              {currentUsage.toLocaleString()} kWh
            </p>
          </div>
          <div className="space-y-0.5">
            <p className="text-[10px] font-medium text-gray-500 uppercase tracking-wide">
              Annual Usage
            </p>
            <p className="text-sm font-semibold text-gray-900">
              {annualUsage.toLocaleString()} kWh
            </p>
          </div>
        </div>

        {/* Peak Usage Alert */}
        <div className="bg-amber-50 rounded-lg px-3 py-2 flex items-start gap-2">
          <AlertTriangle className="w-3.5 h-3.5 text-amber-600 flex-shrink-0 mt-0.5" />
          {locationSpec !== "address" ? (
            <p className="text-[10px] text-amber-700">
              Enter your address to improve forecast accuracy
            </p>
          ) : (
            <p className="text-[10px] text-amber-700">
              Peak usage in {peakMonth.label} is {peakIncrease}% above average
            </p>
          )}
        </div>

        {/* Edit Button */}
        <button
          onClick={onEditUsage}
          className="w-full py-2 text-xs font-semibold text-mainBlue rounded-lg border-2 border-mainBlue 
            hover:bg-blue-50 active:bg-blue-100 transition-colors focus:outline-none focus:ring-2 
            focus:ring-blue-500 focus:ring-offset-2"
        >
          Edit Usage
        </button>
      </div>
    </Pane>
  );
};

export default ForecastUsage;
