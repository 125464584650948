import React from "react";
import {
  Filter,
  Star,
  Clock,
  Leaf,
  Lock,
  Zap,
  HelpCircle,
  DollarSign,
  Moon,
  Sun,
  Gift,
} from "lucide-react";
import RangeSlider from "@/components/ui/range-slider";
import { cn } from "@/lib/utils";
import { usePlansStore, UsageTiers } from "@/providers/planStore";
import { Pane } from "../../Pane";

export const FilterPanel: React.FC = () => {
  const { filters, setFilters, clearFilters, updateFilteredPlans } = usePlansStore();

  const handleFilterChange = (key: string, value: any) => {
    setFilters({
      ...filters,
      [key]: {
        ...filters[key as keyof typeof filters],
        enabled: true,
        value,
      },
    });
    updateFilteredPlans();
  };

  const handlePriceRangeChange = (value: [number, number]) => {
    setFilters({
      ...filters,
      price: {
        ...filters.price,
        enabled: true,
        min: value[0],
        max: value[1],
      },
    });
    updateFilteredPlans();
  };

  const handleFeatureToggle = (featureId: string) => {
    const currentFeatures = filters.features.values;
    const newFeatures = currentFeatures.includes(featureId)
      ? currentFeatures.filter((f) => f !== featureId)
      : [...currentFeatures, featureId];

    setFilters({
      ...filters,
      features: {
        ...filters.features,
        enabled: newFeatures.length > 0,
        values: newFeatures,
      },
    });
    updateFilteredPlans();
  };

  return (
    <div className="sticky w-full top-24 max-h-[calc(100vh-6rem)] overflow-y-auto">
      <Pane>
        <div className="flex items-center gap-2 mb-6">
          <Filter className="w-5 h-5 text-blue-600" />
          <h2 className="text-lg font-semibold">Filter Plans</h2>
        </div>

        <div className="space-y-8">
          {/* Contract Length Slider */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4 text-gray-400" />
                <h3 className="text-sm font-medium text-gray-900">
                  Contract Length
                </h3>
              </div>
              <button className="group">
                <HelpCircle className="w-4 h-4 text-gray-400 group-hover:text-gray-600" />
              </button>
            </div>
            <div className="space-y-3">
              {filters.contractLength.options?.map((length) => (
                <label
                  key={length}
                  className={cn(
                    "flex items-center gap-3 px-3 py-2 rounded-md",
                    "hover:bg-gray-50 cursor-pointer transition-colors"
                  )}
                >
                  <input
                    type="checkbox"
                    checked={filters.contractLength.values.includes(length)}
                    onChange={() => {
                      const newValues = filters.contractLength.values.includes(
                        length
                      )
                        ? filters.contractLength.values.filter(
                            (v) => v !== length
                          )
                        : [...filters.contractLength.values, length];
                      setFilters({
                        ...filters,
                        contractLength: {
                          ...filters.contractLength,
                          enabled: newValues.length > 0,
                          values: newValues,
                        },
                      });
                      updateFilteredPlans();
                    }}
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-600">{length} Months</span>
                </label>
              ))}
            </div>
          </div>

          {/* Monthly Usage Selection */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Zap className="w-4 h-4 text-gray-400" />
                <h3 className="text-sm font-medium text-gray-900">
                  Monthly Usage
                </h3>
              </div>
            </div>
            <div className="space-y-3">
              {Object.values(UsageTiers).map((tier) => (
                <label
                  key={tier}
                  className={cn(
                    "flex items-center gap-3 px-3 py-2 rounded-md",
                    "hover:bg-gray-50 cursor-pointer transition-colors"
                  )}
                >
                  <input
                    type="radio"
                    checked={filters.usageTier.value === tier}
                    onChange={() => handleFilterChange("usageTier", tier)}
                    className="rounded-full border-gray-300 text-blue-600 focus:ring-blue-500"
                  />
                  <span className="text-sm text-gray-600">{tier} kWh</span>
                </label>
              ))}
            </div>
          </div>

          {/* Price Range */}
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <DollarSign className="w-4 h-4 text-gray-400" />
                <h3 className="text-sm font-medium text-gray-900">
                  Price Range
                </h3>
              </div>
            </div>
            <RangeSlider
              min={8}
              max={20}
              step={0.1}
              value={[filters.price.min, filters.price.max]}
              onChange={(value) =>
                handlePriceRangeChange(value as [number, number])
              }
              formatLabel={(value) => `${value}¢`}
            />
          </div>

          {/* Plan Features */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <Star className="w-4 h-4 text-yellow-400" />
              <h3 className="text-sm font-medium text-gray-900">
                Plan Features
              </h3>
            </div>
            <div className="space-y-3">
              {[
                { id: "locked-rate", label: "Locked Rate", icon: Lock },
                { id: "free-nights", label: "Free Nights", icon: Moon },
                { id: "solar-buyback", label: "Solar Buyback", icon: Sun },
                { id: "rewards", label: "Rewards Program", icon: Gift },
              ].map((feature) => {
                const Icon = feature.icon;
                return (
                  <label
                    key={feature.id}
                    className={cn(
                      "flex items-center gap-3 px-3 py-2 rounded-md",
                      "hover:bg-gray-50 cursor-pointer transition-colors"
                    )}
                  >
                    <input
                      type="checkbox"
                      checked={filters.features.values.includes(feature.id)}
                      onChange={() => handleFeatureToggle(feature.id)}
                      className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <Icon className="w-4 h-4 text-gray-400" />
                    <span className="text-sm text-gray-600">
                      {feature.label}
                    </span>
                  </label>
                );
              })}
            </div>
          </div>
        </div>

        {/* Reset Button */}
        <button
          onClick={clearFilters}
          className="mt-8 w-full px-4 py-2 text-sm font-medium text-gray-600 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
        >
          Reset Filters
        </button>
      </Pane>
    </div>
  );
};
