import { PlanInfo } from "@/types/plan";
import { ComparePlanButton } from "./ComparePlanButton";
import {
  Lock,
  Leaf,
  Bookmark,
  Star,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import { StarRating } from "./components/StarRating";
import { PriceDisplay } from "./components/PriceDisplay";

export const PlanCardHeader: React.FC<{ plan: PlanInfo }> = ({ plan }) => {
  return (
    <div className="w-full flex flex-col items-left justify-start max-h-[300px]">
        <div className="grid grid-cols-2 w-full py-2">
          <img
            src={plan.retailer.logo}
            alt={plan.retailer.name}
            className="h-12 object-contain"
            loading="lazy"
          />
          <div className="col-start-2 row-start-1 flex flex-col items-end justify-center pointer-events-auto cursor-pointer">
            <ComparePlanButton id={plan.getxid} />
          </div>
        </div>

        <div className="col-span-2 row-start-2 flex flex-col items-start justify-start">
        <h2 className="text-xl font-bold text-mainBlue">
            {plan.info["plan-name"]}
          </h2>
          <StarRating rating={plan["ai-analysis"]?.rating || -1} />
          <PriceDisplay plan={plan} />
          <div className="grid grid-cols-2 grid-rows-2 w-full">
            <div className="col-start-1 row-start-1 flex flex-row items-center justify-center gap-1">
              <Lock className="w-4 h-4 text-gray-400" />
              <div className="text-sm text-gray-600 text-center">{plan["term-length"]} Month Fixed</div>
            </div>

            <div className="col-start-2 row-start-1 flex flex-row items-center justify-center gap-1">
              <Leaf className="w-4 h-4 text-green-600" />
              <div className="text-sm text-gray-600 text-center">{plan["ai-analysis"]?.pctRenewable?.percentage.toFixed(0) || 0}% Green</div>
            </div>
          </div>
        </div>
    </div>
  );
};
