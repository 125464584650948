import { useIsMobile } from "@/hooks/use-mobile";
import ForecastUsage from "./ForecastUsage";
import { PlansGrid } from "./PlansGrid";
import { RecommendedPlans } from "./RecommendedPlans";
import { FilterPanel } from "./filters/Filters";
export const PlanDisplay = () => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <div className="w-full h-full"><PlansGrid /></div>;
  } else {
    return (
      <div className="w-full h-full grid grid-cols-[25%_75%] gap-2">
        <div className="container !justify-start">
          <ForecastUsage />
          <FilterPanel />
        </div>
        <div className="container !justify-start">
          {/* <RecommendedPlans /> */}
          <PlansGrid />
        </div>
      </div>
    );
  }
};
