import React, { useState } from 'react';
import { Lock, Leaf, Bookmark, Star, ChevronDown, ChevronUp } from 'lucide-react';
import { PlanInfo, PlanPricing } from '@/types/plan';
import { cn } from '@/lib/utils';
import { usePlansStore } from '@/providers/planStore';
import { PlanCardHeader } from './PlanCardHeader';
import { PlanCardContent } from './PlanCardContent';
import { PlanCardFooter } from './PlanCardFooter';
import { Pane } from '@/views/Plans2/Pane';
interface PlanCardProps {
  plan: PlanInfo;
  onAddToCompare: (plan: PlanInfo) => void;
  isInCompare: boolean;
  className?: string;
}

export const PlanCard2: React.FC<PlanCardProps> = ({ plan, onAddToCompare, isInCompare, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const starRating = Math.round((plan.valueScore + plan.flexibilityScore + plan.featuresScore) / 20);
  const planStore = usePlansStore();
  const filters = planStore.filters;

  return (
    <Pane className="border-2 border-gray-200 rounded-lg">
      <div className={cn("relative h-[600px]","flex flex-col justify-between w-full p-2 pointer-events-none ", className)}>
        <PlanCardHeader plan={plan} />
        <PlanCardContent plan={plan} />
        <PlanCardFooter plan={plan} />
      </div>
    </Pane>
  );
};
