import Page from "@/components/Page";
import { usePlansStore } from "@/providers/planStore";
import { pgPlanInfo, PlanInfo } from "@/types/plan";
import React, { useState } from "react";
import { useEnrollStore, EnrollStep } from "@/providers/enrollStore";
import { useSessionStore } from "@/providers/store";
import { CompareDrawer } from "./components/ProductCompare/ProductCompare";
import Api from "@/utils/api";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PlanMap } from "./components/PlanMap";
import { PlanProgress } from "./components/PlanProgress";
import { PlanDisplay } from "./components/PlanDisplay";
import { PlanDetails } from "./components/PlanDetails/PlanDetails";


export const Plans2: React.FC = () => {
  const loaderZipCode = useLoaderData() as string;
  const planStore = usePlansStore();
  const sessionStore = useSessionStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { enroll, setEnroll_service_address_zip, setEnroll_service_coords, setEnroll_step } =
    useEnrollStore();
  const enrollmentPath =
    location.pathname.split("/").slice(0, -1).join("/") + "/enrollment";
  const { zipCode: urlZipCode } = useParams<{ zipCode: string }>();
  const [tdus, setTdus] = useState<any[]>([]);
  const [allTdus, setAllTdus] = useState<any[]>([]);
  const [numPlansToDisplay, setNumPlansToDisplay] = useState(50);
  const zipCode = urlZipCode || loaderZipCode || enroll.service_address_zip;
  const handlePlanClick = (plan: PlanInfo) => {
    planStore.setSelectedPlan(plan);
  };

  const showMorePlans = () => {
    setNumPlansToDisplay(numPlansToDisplay + 50);
  };

  React.useEffect(() => {
    if (zipCode) {
      setEnroll_service_address_zip(zipCode);
      sessionStore.setLocationSpec("zip");
      planStore.fetchPlans(zipCode);
      Api.getZipInfo(zipCode).then((zipInfo) => {
        console.log("zipInfo", zipInfo);
        if (zipInfo?.data?.lat && zipInfo?.data?.lon) {
          console.log("setting coords", [zipInfo.data.lat, zipInfo.data.lon]);
          setEnroll_service_coords([zipInfo.data.lat, zipInfo.data.lon]);
        }
      });
      setEnroll_step(EnrollStep.PLAN_SELECTION);
    }
  }, [zipCode]);

  React.useEffect(() => {
    if (planStore.filteredPlans.length > 0) {
      const tduSet = new Set(planStore.filteredPlans.map((plan) => plan.tdu));
      setTdus(Array.from(tduSet));
      Api.getTDUs().then((tdus) => {
        setAllTdus(tdus);
      });
    }
  }, [planStore.filteredPlans]);






  return (
    <Page>
      <PlanProgress />
      <PlanMap />
      <CompareDrawer />
      <PlanDisplay />
      <PlanDetails />
      
    </Page>
  );
};
